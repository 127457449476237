import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Input from "../assets/UI/Input"
import LabelButton from "../assets/UI/LabelButton"
import Button from "../assets/UI/Button"
import ToggleButton from "../assets/UI/ToggleButton"
import Modal from "../assets/UI/Modal"
import { ALL_NETWORKS } from "../apollo/graphql/Query/allNetworks"
import { CREATE_STATIC_INVOICE } from "../apollo/graphql/Mutation/createStaticInvoice"
import AddressDisplay from "../assets/UI/AddressDisplay"
import styles from "../assets/styles/Components/StaticInvoice.module.css"

const StaticInvoice = ({ walletId }) => {
  const [invoiceName, setInvoiceName] = useState("")
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({})
  const [toggleState, setToggleState] = useState(false)
  const [staticInvoiceData, setStaticInvoiceData] = useState(null)

  const { loading, data, error } = useQuery(ALL_NETWORKS)

  const [createStaticInvoice, { loading: createLoading }] = useMutation(
    CREATE_STATIC_INVOICE
  )

  const handleToggle = () => {
    setToggleState(!toggleState)
  }

  const handleNetworkSelect = (network) => {
    setSelectedNetwork(network)
    setIsModalOpen(false)
    setErrors((prevErrors) => ({ ...prevErrors, network: "" }))
  }

  const handleCreateInvoice = async () => {
    const newErrors = {}
    if (!invoiceName) newErrors.invoiceName = "Please enter an invoice name"
    if (!selectedNetwork) newErrors.network = "Please select a network"

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    } else {
      setErrors({})
    }

    try {
      const { data } = await createStaticInvoice({
        variables: {
          walletId,
          name: invoiceName,
          network: selectedNetwork.name,
        },
      })

      if (data.createStaticInvoice.ok) {
        setMessage("Static Invoice created successfully.")
        setStaticInvoiceData(data.createStaticInvoice.staticInvoice)
        setInvoiceName("")
        setSelectedNetwork(null)
        setErrors({})

        if (!toggleState) {
          window.open(
            `https://vortexonline.xyz/invoice/${data.createStaticInvoice.staticInvoice.id}`,
            // `http://localhost:3000/invoice${data.createStaticInvoice.staticInvoice.id}`,
            "_blank"
          )
          setStaticInvoiceData(null)
        }
      } else {
        setMessage(`Error: ${data.createStaticInvoice.message}`)
      }
    } catch (err) {
      setMessage("An unexpected error occurred.")
    }
  }

  const handleStartNewInvoice = () => {
    setInvoiceName("")
    setSelectedNetwork(null)
    setErrors({})
    setStaticInvoiceData(null)
    setMessage("")
  }

  return (
    <div className={styles.container}>
      {staticInvoiceData && toggleState ? (
        <div className={styles.content}>
          <AddressDisplay
            address={`https://vortexonline.xyz/invoice/${staticInvoiceData.id}`}
          />
          {/* <AddressDisplay
            address={`http://localhost:3000/${staticInvoiceData.id}`}
          /> */}
          <Button onClick={handleStartNewInvoice}>
            Create New Static Invoice
          </Button>
        </div>
      ) : (
        <>
          <Input
            label="Invoice Name"
            value={invoiceName}
            onChange={(e) => setInvoiceName(e.target.value)}
            placeholder="Enter invoice name"
            error={errors.invoiceName}
            onFocus={() =>
              setErrors((prevErrors) => ({ ...prevErrors, invoiceName: "" }))
            }
          />

          <LabelButton
            label="Network"
            onClick={() => setIsModalOpen(true)}
            arrow={true}
            error={errors.network}
            icon={selectedNetwork ? selectedNetwork.logoUrl : null}
          >
            {selectedNetwork
              ? `${selectedNetwork.fullName}`
              : "Choose a network"}
          </LabelButton>

          <div className={styles.toggleBtn}>
            <span>Only link</span>
            <ToggleButton isChecked={toggleState} onToggle={handleToggle} />
          </div>

          <div className={styles.invoiceBtn}>
            <Button onClick={handleCreateInvoice} disabled={createLoading}>
              {createLoading ? "Creating..." : "Create"}
            </Button>
          </div>

          {message && <p>{message}</p>}

          {isModalOpen && (
            <Modal title="Select Network" onClose={() => setIsModalOpen(false)}>
              {loading ? (
                <p>Loading networks...</p>
              ) : error ? (
                <p>Error loading networks: {error.message}</p>
              ) : (
                <div>
                  {data.allNetworks.map((network) => (
                    <div key={network.id} className={styles.modalLabelButton}>
                      <LabelButton
                        onClick={() => handleNetworkSelect(network)}
                        icon={network.logoUrl}
                      >
                        {network.fullName}
                      </LabelButton>
                    </div>
                  ))}
                </div>
              )}
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default StaticInvoice
